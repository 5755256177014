/* globals $ */

import * as React from 'react';
import { uniq } from 'lodash';
import {
  Row, Form as BsForm, InputGroup, Badge, Button, Col,
} from 'react-bootstrap';
import moment from 'moment';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import { Icon } from './Icon.jsx';
import { randomHex, Translator } from '../../plugins/dux-utils';
import { getDateLocale, getDefaultRanges } from '../lib/date';

export function Checkbox({
  id,
  formId,
  label,
  block = false,
  button = false,
  validation = null,
  className = '',
  onLoad,
  ...forwarded
}) {
  const classes = [className];
  const parsedId = formId ? `${formId}_${id}` : id;
  const parsedName = formId ? `${formId}[${id}]` : id;
  const self = React.createRef();

  if (block) classes.push('form-check-block');
  if (button) classes.push('form-check-btn');

  React.useEffect(() => {
    const obj = $(self.current);
    if (onLoad) onLoad(obj);
    if (validation) obj.duxValidate();
  });

  return (
    <BsForm.Check
      ref={self}
      type="checkbox"
      id={parsedId}
      name={parsedName}
      label={label}
      className={classes.join(' ').trim()}
      data-validate={validation}
      {...forwarded}
    />
  );
}

export function Select({
  id,
  label,
  formId = '',
  options = [],
  search = false,
  tags = false,
  multiple = false,
  placeholder = null,
  validation = null,
  defaultValue = null,
  onChange = null,
  readOnly = false,
  ...forwarded
}) {
  const self = React.createRef();
  const parsedId = formId ? `${formId}_${id}` : id;
  let parsedName = formId ? `${formId}[${id}]` : id;
  const parsedOptions = options.map((option) => {
    if (typeof option === 'object') return option;

    return { name: option, value: option };
  });

  if (multiple) parsedName += '[]';

  React.useEffect(() => {
    const obj = $(self.current);
    const selectOptions = {};
    const minWidth = parseInt(
      obj.css('min-width').replace(/px|em|ex|%|in|cm|mm|pt|pc/i, ''),
      10,
    );

    if (validation) obj.duxValidate();
    if (!search) selectOptions.minimumResultsForSearch = -1;
    if (tags) selectOptions.tags = true;
    if (minWidth === 0) selectOptions.width = '100%';
    if (placeholder) selectOptions.placeholder = placeholder;
    if (readOnly) selectOptions.disabled = true;

    const parentForm = $(`#${parsedId}`).closest('form');
    if (parentForm[0]) selectOptions.dropdownParent = parentForm;

    obj.off('change');
    obj.select2(selectOptions);
    if (onChange) obj.change((e) => onChange(e.target, obj.val()));
  });

  return (
    <BsForm.Group controlId={parsedId} className={readOnly ? 'select2-readonly' : null}>
      <BsForm.Label>{label}</BsForm.Label>
      <BsForm.Control
        ref={self}
        as="select"
        name={parsedName}
        multiple={multiple}
        data-validate={validation}
        defaultValue={defaultValue}
        onChange={onChange}
        {...forwarded}
      >
        {parsedOptions.map((type) => (
          <option key={type.value} value={type.value}>
            {type.name}
          </option>
        ))}
      </BsForm.Control>
    </BsForm.Group>
  );
}

export function SelectTree({
  id,
  label,
  formId = '',
  options = [],
  onChange = null,
  readOnly = false,
  multiple = false,
  defaultValue = null,
  ...forwarded
}) {
  const translator = new Translator({
    search: {
      en: 'Search...',
      es: 'Buscar...',
      'pt-br': 'Pesquisar...',
    },
    selectedItems: {
      en: 'items selected',
      es: 'elementos seleccionados',
      'pt-br': 'itens selecionados',
    },
    choose: {
      en: 'Choose...',
      es: 'Elegir...',
      'pt-br': 'Escolha...',
    },
    noMatches: {
      en: 'No matches found',
      es: 'No se encontraron coincidencias',
      'pt-br': 'Nenhum resultado encontrado',
    },
  });

  const [parsedOptions, setParsedOptions] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [selectedNodes, setSelectedNodes] = React.useState([]);
  const [valueLabel, setValueLabel] = React.useState('');

  const transformParent = (option) => {
    const isParent = option.children && option.children.length > 0;
    let children = [];

    if (isParent) {
      children = option.children.map((child) => transformParent(child));
    }

    let checked = false;
    if (multiple) {
      checked = isParent
        ? children.length > 0 && children.every((child) => child.checked)
        : defaultValue.includes(option.value);
    } else {
      checked = defaultValue === option.value;
    }

    const node = {
      label: option.label,
      value: option.value,
      children,
      checked,
      disabled: !multiple && !!isParent,
      className: !multiple && !!isParent ? 'parent-node' : 'child-node',
    };

    if (node.checked && !isParent) {
      setSelected((prev) => [...prev, node.value]);
      setSelectedNodes((prev) => [...prev, node]);
    }

    return node;
  };

  React.useEffect(() => {
    setSelected([]);
    setSelectedNodes([]);
    setParsedOptions(options.map((option) => transformParent(option)));
  }, [options, defaultValue]);

  React.useEffect(() => {
    if (multiple) {
      setValueLabel(`${selected.length} ${translator.get('selectedItems')}`);
    } else {
      setValueLabel(selectedNodes[0]?.label || '');
    }
  }, [selected]);

  const handleOnChange = React.useCallback((_, newSelectedNodes) => {
    const selectedValues = [];

    newSelectedNodes.forEach((node) => {
      if (node.value instanceof Array) {
        selectedValues.push(...node.value);
        return;
      }

      selectedValues.push(node.value);
    });

    if (onChange) {
      onChange(multiple ? selectedValues : selectedValues[0]);
    }

    setSelectedNodes(newSelectedNodes);
    setSelected(selectedValues);
  });

  const dropdown = React.useMemo(() => (
    <DropdownTreeSelect
      texts={{ placeholder: ' ', noMatches: translator.get('noMatches'), inlineSearchPlaceholder: translator.get('search') }}
      data={parsedOptions}
      readOnly={readOnly}
      onChange={handleOnChange}
      mode={multiple ? 'multiSelect' : 'radioSelect'}
      inlineSearchInput
      {...forwarded}
    />
  ), [parsedOptions]);

  return (
    <>
      <HiddenInput
        id={id}
        formId={formId}
        multiple={multiple}
        value={multiple ? selected : selected[0]}
      />
      <BsForm.Label>{label}</BsForm.Label>
      {dropdown}
      <div
        className={selected.length > 0 ? '' : 'text-muted'}
        style={{
          position: 'absolute',
          bottom: '14px',
          left: '16px',
        }}
      >
        {selected.length > 0 ? valueLabel : translator.get('choose')}
      </div>
    </>
  );
}

export function Input({
  id,
  formId,
  label,
  onLoad,
  validation = null,
  ...forwarded
}) {
  const self = React.createRef();
  const parsedId = formId ? `${formId}_${id}` : id;
  const parsedName = formId ? `${formId}[${id}]` : id;

  React.useEffect(() => {
    const obj = $(self.current);
    if (onLoad) onLoad(obj);
    if (validation) obj.duxValidate();
  });

  return (
    <BsForm.Group controlId={parsedId}>
      {label && <BsForm.Label>{label}</BsForm.Label>}
      <BsForm.Control
        ref={self}
        name={parsedName}
        data-validate={validation}
        {...forwarded}
      />
    </BsForm.Group>
  );
}

export const HiddenInput = React.forwardRef(({
  id,
  formId,
  ...forwarded
}, ref) => {
  const parsedId = formId ? `${formId}_${id}` : id;
  const parsedName = formId ? `${formId}[${id}]` : id;

  return (
    <BsForm.Control
      ref={ref}
      id={parsedId}
      name={parsedName}
      hidden
      readOnly
      {...forwarded}
    />
  );
});

export function FileInput({
  id,
  formId,
  label,
  onLoad,
  validation = null,
  className = '',
  multiple = false,
  onSelection = null,
  accept = '*/*',
  ...forwarded
}) {
  const classes = [className, 'cursor-pointer'];
  const self = React.createRef();
  const fileRef = React.createRef();
  const parsedId = formId ? `${formId}_${id}` : id;
  const parsedName = formId ? `${formId}[${id}]` : id;

  React.useEffect(() => {
    const obj = $(self.current);
    if (onLoad) onLoad(obj);
    if (validation) obj.duxValidate();
  });

  const triggerFileInput = React.useCallback(() => {
    fileRef.current.click();
  });

  const fileSelected = React.useCallback(() => {
    const files = fileRef.current.files;
    let inputText = '';

    if (files.length > 1) {
      inputText += `${files.length} files:  `;
    }
    for (let i = 0; i < files.length; i += 1) {
      inputText += `${files[i].name}  `;
    }

    if (onSelection) onSelection(files);

    self.current.value = inputText;
  });

  return (
    <BsForm.Group controlId={parsedId}>
      <BsForm.Label>{label}</BsForm.Label>
      <InputGroup onClick={triggerFileInput} className="cursor-pointer">
        <InputGroup.Prepend>
          <InputGroup.Text>
            <Icon name="upload" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <BsForm.Control
          ref={self}
          className={classes.join(' ').trim()}
          data-validate={validation}
          readOnly
          {...forwarded}
        />
        <BsForm.File
          name={parsedName}
          ref={fileRef}
          onChange={fileSelected}
          multiple={multiple}
          accept={accept}
          hidden
        />
      </InputGroup>
    </BsForm.Group>
  );
}

export const DateRangePicker = React.memo(({
  id = null,
  formId = null,
  label = null,
  onLoad = null,
  onChange = null,
  now = moment().startOf('minute'),
  timeIncrement = 5,
  endDate = null,
  startDate = null,
  timePicker = true,
  singleDatePicker = false,
  format,
  opens = 'left',
  className = '',
  validation = null,
  variant = 'primary',
  iconClass = 'text-white',
  textClass = 'fs-12 text-center',
  width = '100%',
  fixedRange = false,
  ranges = null,
  type = 'datetime',
  language = 'en',
  ...forwarded
}) => {
  const classes = [className, 'cursor-pointer', 'border-0', textClass];
  const self = React.createRef();
  const parsedId = formId ? `${formId}_${id}` : id;
  const parsedName = formId ? `${formId}[${id}]` : id;
  const parsedNow = now.clone().subtract(now.minute() % timeIncrement, 'minutes');
  const parsedStartDate = startDate || (singleDatePicker ? parsedNow : parsedNow.clone().subtract(24, 'hours'));
  const parsedEndDate = endDate || parsedNow;

  const getConfig = React.useCallback(() => {
    let config = {
      timePicker,
      timePicker24Hour: true,
      timePickerIncrement: timeIncrement,
      singleDatePicker,
      startDate: parsedStartDate,
      endDate: parsedEndDate,
      opens,
      alwaysShowCalendars: !fixedRange,
      showCustomRangeLabel: !fixedRange,
      locale: getDateLocale(language, type, timePicker, format),
    };

    if (!singleDatePicker) {
      config.ranges = ranges || getDefaultRanges(language, parsedEndDate);
    }

    if (type === 'monthly') {
      config = {
        ...config,
        ranges: [],
        showCustomRangeLabel: false,
        showDropdowns: true,
        singleDatePicker: true,
        autoUpdateInput: true,
      };
    }

    return config;
  });

  React.useEffect(() => {
    const obj = $(self.current);
    obj.daterangepicker(getConfig());

    if (onLoad) onLoad(obj);
    if (validation) obj.duxValidate();

    obj.closest('.modal').removeAttr('tabindex');

    if (type === 'monthly') {
      obj.on('show.daterangepicker', (ev, picker) => {
        $(picker.container[0]).addClass(`datepicker-${type}`);
      });

      obj.on('hide.daterangepicker', (ev, instance) => {
        const td = $(instance.container).find('.table-condensed tbody tr:nth-child(3) td:first-child');
        setTimeout(() => {
          td.trigger('mousedown');
        }, 1);
      });
    }

    obj.on('apply.daterangepicker', (ev, picker) => {
      let start = picker.startDate;
      let end = picker.endDate;

      if (type === 'monthly') {
        start = picker.startDate.startOf('month');
        end = picker.endDate.endOf('month');
      }

      if (onChange) onChange(start, end);
    });
  });

  return (
    <BsForm.Group controlId={parsedId} style={{ width }}>
      { label && (
        <BsForm.Label>{label}</BsForm.Label>
      )}
      <InputGroup className={`cursor-pointer overflow-hidden rounded-small border border-${variant}`}>
        <InputGroup.Prepend className={`bg-${variant} `}>
          <InputGroup.Text className={`${iconClass} bg-${variant}`}>
            <Icon name="calendar" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <BsForm.Control
          ref={self}
          name={parsedName}
          className={classes.join(' ').trim()}
          data-validate={validation}
          {...forwarded}
        />
      </InputGroup>
    </BsForm.Group>
  );
});

export function ListEditor({
  id,
  formId,
  label,
  className = '',
  defaultValues = [],
  bordered = true,
}) {
  const classes = ['p-2', className];
  const tempId = `list_editor_${randomHex()}`;
  const [updatedValues, setUpdatedValues] = React.useState(defaultValues);

  if (bordered) classes.push('border');

  const addValue = React.useCallback((input) => {
    const value = input.value.trim();

    if (value) {
      setUpdatedValues(uniq([...updatedValues, value]));
      // eslint-disable-next-line no-param-reassign
      input.value = '';
    }
  });

  const removeValue = React.useCallback((value) => {
    setUpdatedValues(updatedValues.filter((v) => v !== value));
  });

  const inputGroupOnClick = React.useCallback((e) => {
    const inputGroup = e.target.closest('.input-group');
    const input = inputGroup.querySelector('input');
    addValue(input);
  });

  const inputKeyDown = React.useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addValue(e.target);
    }
  });

  return (
    <>
      <HiddenInput id={id} formId={formId} value={JSON.stringify(updatedValues)} />
      <BsForm.Label>{label}</BsForm.Label>
      <div className={classes.join(' ')}>
        <Row className="gutter-1 align-items-center">
          { updatedValues.map((value) => (
            <Col xs="auto" key={value}>
              <Badge variant="light" className="p-2 d-flex align-items-center">
                { value }
                <Icon name="times" className="fs-12 ml-2 cursor-pointer" onClick={() => removeValue(value)} />
              </Badge>
            </Col>
          ))}
          <Col xs="auto">
            <InputGroup className="border rounded-small">
              <BsForm.Control
                id={tempId}
                name={tempId}
                onKeyDown={inputKeyDown}
                className="border-0"
              />
              <InputGroup.Append onClick={inputGroupOnClick}>
                <Button variant="light border-left">
                  <Icon name="plus fs-12" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}

export const Form = React.forwardRef(({
  children,
  validate = false,
  withBorders = false,
  authenticityToken = null,
  className = '',
  method = 'post',
  ...forwarded
}, ref) => {
  const classes = [className];
  const self = ref || React.createRef();

  if (withBorders) classes.push('form-with-borders');

  React.useEffect(() => {
    const obj = $(self.current);
    if (validate) obj.duxValidate();
  });

  return (
    <BsForm
      ref={self}
      className={classes.join(' ').trim()}
      method={method === 'get' ? 'get' : 'post'}
      noValidate
      {...forwarded}
    >
      <HiddenInput name="_method" value={method} />
      { authenticityToken
        && <HiddenInput id="authenticity_token" defaultValue={authenticityToken} /> }
      <Row className="gutter-1">{children}</Row>
    </BsForm>
  );
});
