import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Translator } from '../../../global/translator';
import {
  MODAL_TYPES,
  reportErrorDispatch,
  reportModalTypeDispatch,
  reportPlotKeysDispatch,
  reportPlotKeysSelector,
  reportPlotSelectedKeyDispatch,
  reportPlotSelectedKeySelector,
  reportPlotSelector,
  reportSelector,
} from '../report-utils';
import { removePlot } from '../report_plot_base/ReportPlotDelete.jsx';
import { ReportPlotModalWrapper } from '../report_plot_base/ReportPlotModalWrapper.jsx';
import { ReportPlotFormWrapper } from '../report_plot_base/ReportPlotFormWrapper.jsx';
import { Input, Select } from '../../../duxfront/duxstrap/components/Form.jsx';

export function ReportScadaEditAccumulatorForm() {
  const translator = new Translator();
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const report = reportSelector(['templates', 'templateGroups']);
  const updateError = reportErrorDispatch();
  const plotKeys = reportPlotKeysSelector();
  const updatePlotKeys = reportPlotKeysDispatch();
  const updateSelectedModalType = reportModalTypeDispatch();
  const updateSelectedPlotKey = reportPlotSelectedKeyDispatch();
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const formId = 'scada_accumulator';
  const title = `${translator.get('general.edit')} ${translator.get('general.accumulator')}`;
  const scadaGroup = report?.templateGroups.find((g) => g.groupType === 'accumulator');
  const templateOptions = scadaGroup?.templates.map((t) => ({ name: t.title, value: t.key })) || [];

  const removeLocation = React.useCallback(() => {
    removePlot(plot, plotKeys, updatePlotKeys, updateError);
    updateSelectedModalType(null);
    updateSelectedPlotKey(null);
  }, [plot, plotKeys, updatePlotKeys, updateError, updateSelectedModalType, updateSelectedPlotKey]);

  return plot && (
    <ReportPlotModalWrapper title={title} modalType={MODAL_TYPES.SCADA_EDIT_ACCUMULATOR}>
      <ReportPlotFormWrapper formId={formId} onRemove={removeLocation}>
        <Col sm={12}>
          <Input
            id="metric_title"
            formId={formId}
            label={translator.get('general.associated_metric_name')}
            defaultValue={plot.metricTitle}
          />
        </Col>

        <Col sm={12}>
          <Select
            id="template"
            formId={formId}
            label={translator.get('general.template')}
            options={templateOptions}
            defaultValue={plot.template}
          />
        </Col>
      </ReportPlotFormWrapper>
    </ReportPlotModalWrapper>
  );
}
