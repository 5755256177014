/* eslint-disable max-len */

import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Dashbox, DashboxTitle, DashboxBody } from './Dashbox.jsx';
import { DuxPlot } from '../plugins/dux-plot';
import { Icon } from '../../duxstrap/components/Icon.jsx';

export function Plot({ plotRef = null, config }) {
  /*
    Expected config:
      {
        title: 'Valor Movimentado Mensal',
        timeInterval: <'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'>
        type: <'time', 'line', 'doughnut', 'pie', 'category'>
        smoothness: false,
        yAxis: [
          {
            id: 'axis-1',
            position: 'left',
            label: 'Valor (BRL)',
            stacked: false,
          }
        ],
        data: [
          {
            id: 'plot_id',
            label: 'label',
            type: <'line', 'area', 'bar'>,
            color: 'color',
            yAxisId: 'axis_id',
            values: {
              x: [],
              y: []
            },
          }
        ],
        annotations: [
          {
            value: 25,
            color: 'red',
            orientation: 'horizontal',
            scaleId: '',
            label: 'TESTE',
            labelPosition: 'left',
          }
        ]
      }
   */

  const canvasRef = React.useRef();
  const apiRef = plotRef || React.useRef();
  const duxPlot = new DuxPlot();
  const zoomConfig = {
    pan: {
      enabled: false,
    },
    zoom: {
      mode: 'xy',
      enabled: true,
      drag: {
        borderColor: 'rgba(0,0,0,0.45)',
        borderWidth: 1,
        backgroundColor: 'rgba(0,0,0,0.15)',
        animationDuration: 0,
      },
    },
  };

  apiRef.current = duxPlot;

  React.useEffect(() => {
    duxPlot.setType(config.type);
    duxPlot.setup(canvasRef.current);

    if (config.type === 'doughnut' || config.type === 'pie') {
      config.data.forEach((data) => {
        duxPlot.addPieDataset(data.label);
        duxPlot.setData(data.values);
      });
    } else {
      for (let k = 0; k < config.yAxis.length; k += 1) {
        duxPlot.addY(
          config.yAxis[k].label,
          config.yAxis[k].position,
          { stacked: !!config.yAxis[k].stacked, gridLines: { drawOnChartArea: k < 1 } },
          config.yAxis[k].id,
        );
      }

      config.data.forEach((data) => {
        const index = duxPlot.addDataset(data.label, data.color, data.type, {}, data.yAxisId);
        duxPlot.setData(data.values, index);
      });

      if (config.timeInterval && config.data.length > 0) {
        duxPlot.setX({
          time: { unit: config.timeInterval },
          offset: config.data[0].type === 'bar',
          stacked: config.data.findIndex((y) => y.type === 'bar') > -1 && config.yAxis.findIndex((y) => y.stacked) > -1,
        });
      }

      if (config.smoothness) duxPlot.setLineTension(config.smoothness);
      if (config.data.length > 1) duxPlot.enableLegend();
      if (config.dataLabel) duxPlot.enableDataLabels(config.dataLabel);

      duxPlot.enableZoom(zoomConfig);
      duxPlot.enableCrosshair();
      duxPlot.applyAnnotations(config.annotations);
    }

    duxPlot.apply();

    return () => {
      duxPlot.destroy();
    };
  });

  return (
    <canvas ref={canvasRef} />
  );
}

export function DashPlot({ config, plotRef }) {
  const plotApi = plotRef || React.useRef();

  return (
    <Col md={config.columnSize || 12}>
      <Dashbox>
        <DashboxTitle title={config.title}>
          <Icon
            className="fs-14 text-gray-500"
            name="search-minus"
            onClick={() => plotApi.current.zoomOut()}
          />
        </DashboxTitle>
        <DashboxBody style={{ height: 400 }}>
          <Plot config={config} plotRef={plotApi} />
        </DashboxBody>
      </Dashbox>
    </Col>
  );
}
