import * as React from 'react';
import _ from 'lodash';
import { Modal, Toast } from 'react-bootstrap';
import { BootBox } from '../../duxfront/duxstrap/vendor/bootbox';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { globalDispatch, globalSelector } from '../../duxfront/plugins/dux-redux';
import { LocationBox } from './LocationBox.jsx';
import {
  getJSON, removeByKey, updateByKey,
} from '../../duxfront/plugins/dux-utils';
import { Project } from '../../global/project';
import LocationsMap from '../maps/LocationsMap.jsx';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../global/translator';
import { LocationsMapSelection } from '../maps/LocationsMapSelection.jsx';

function LocationLoader({ locations }) {
  const project = new Project();
  const currentLocations = globalSelector('locations');
  const updateLocations = globalDispatch('locations');
  const updateScreenNotifications = globalDispatch('screen_notifications');
  const autoUpdateSeconds = project.config.autoUpdateLocations;

  React.useEffect(() => {
    if (!currentLocations) {
      updateLocations(locations);

      if (autoUpdateSeconds) BootBox.alert('Atualizações automáticas estão ativadas.');
    }

    if (!autoUpdateSeconds) return;

    const intervalId = setInterval(() => {
      getJSON(
        project.locationUpdatesPath,
        { seconds_ago: autoUpdateSeconds },
        (data) => {
          if (data.locations.length > 0) {
            updateLocations(updateByKey(currentLocations, data.locations, 'code'));
          }

          updateScreenNotifications(data.screenNotifications);
        },
      );
    }, autoUpdateSeconds * 1000);

    return () => clearInterval(intervalId);
  });

  return null;
}

function NotificationBar() {
  const screenNotifications = globalSelector('screen_notifications') || [];
  const updateScreenNotifications = globalDispatch('screen_notifications');
  const [enableSound, setEnableSound] = React.useState(false);

  const dismissNotification = React.useCallback((key) => {
    updateScreenNotifications(removeByKey(screenNotifications, key));
  });

  React.useEffect(() => setEnableSound(screenNotifications.length > 0), [screenNotifications]);
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (!enableSound) {
        return;
      }

      new Audio(screenNotifications[0].sound).play();
    }, 4000);

    return () => clearInterval(intervalId);
  });

  return (
    <div className="fix-content-corner-top mr-2" style={{ marginTop: '4rem' }}>
      { screenNotifications.map((notification) => (
        <Toast show onClose={() => dismissNotification(notification.key)} key={notification.key}>
          <Toast.Header>
            <strong className="mr-auto">{notification.title}</strong>
            <small>{notification.time}</small>
          </Toast.Header>
          <Toast.Body>{notification.message}</Toast.Body>
        </Toast>
      ))}
    </div>
  );
}

function LocationMapModal({ maps, selectedMaps, onSelectedMapsChange }) {
  const [modal, setModal] = React.useState(false);
  const self = React.createRef();
  const translator = new Translator();

  return (
    <>
      <Button
        className="fix-content-corner-top mt-2 mr-2"
        rounded
        icon
        size="sm"
        variant="light"
        onClick={() => setModal(true)}
      >
        <Icon name="map" offset={1} />
      </Button>

      <Modal centered show={modal} onHide={() => setModal(false)}>
        <Modal.Header closeButton onHide={() => setModal(false)}>
          <Modal.Title>{translator.get('titles.map-selection')}</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={self}>
          <LocationsMapSelection maps={maps} selectedMaps={selectedMaps} onSelectionChange={onSelectedMapsChange} />
        </Modal.Body>
      </Modal>
    </>
  );
}

function LocationWindow({ location }) {
  return (
    <div style={{ maxWidth: '400px' }}>
      <LocationBox location={_.cloneDeep(location)} severitiesWindowOpen={['high']} />
    </div>
  );
}

function LocationsMapWrapper() {
  const project = new Project();
  const locations = globalSelector('locations');
  const [maps, setMaps] = React.useState([]);
  const [selectedMaps, setSelectedMaps] = React.useState([]);

  React.useEffect(() => {
    getJSON(`/projects/${project.code}/maps`, {}, (data) => {
      setMaps(data.object);
      setSelectedMaps(data.object.filter((m) => m.show_in_project_locations));
    });
  }, []);

  return (
    <>
      <NotificationBar />
      <LocationsMap
        className="vh-100"
        locations={locations}
        locationWindow={LocationWindow}
        maps={selectedMaps}
      />
      <LocationMapModal
        maps={maps}
        selectedMaps={selectedMaps}
        onSelectedMapsChange={setSelectedMaps}
      />
    </>
  );
}

export function LocationMap(props) {
  const { locations } = props;

  return (
    <GlobalWrapper {...props}>
      <LocationLoader locations={locations} />
      <LocationsMapWrapper />
    </GlobalWrapper>
  );
}
