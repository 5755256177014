import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  applyNodeChanges,
  Background,
  Controls,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react';
import {
  MODAL_TYPES,
  reportModalTypeDispatch,
  reportPlotKeysSelector,
  reportPlotSelectedKeyDispatch,
  reportScadaClickPositionDispatch,
  reportScadaClickPositionSelector, reportScadaDeleteDispatch,
  reportScadaNewConnectionDispatch, reportScadaScadaLockedDispatch, reportScadaScadaLockedSelector,
  reportScadaUpdateDispatch,
} from '../report-utils';
import { ReportTopBar } from '../report_base/ReportTopBar.jsx';
import { ContextMenu } from '../../../duxfront/duxdash/components/ContextMenu.jsx';
import { ReportPlotDataLoader } from '../report_plot_base/ReportPlotDataLoader.jsx';
import { ReportError } from '../report_base/ReportError.jsx';
import { Project } from '../../../global/project';
import { ReportScadaEditLocationForm } from './ReportScadaEditLocationForm.jsx';
import { ReportScadaNewLocation, ReportScadaNewLocationForm } from './ReportScadaNewLocation.jsx';
import { ReportScadaPlotStateManager } from './ReportScadaPlotStateManager.jsx';
import { ReportScadaPlotUpdater } from './ReportScadaPlotUpdater.jsx';
import { ReportScadaPlotRemover } from './ReportScadaPlotRemover.jsx';
import { ReportScadaStandardLocation } from './ReportScadaStandardLocation.jsx';
import { ReportScadaNewConnection } from './ReportScadaNewConnection.jsx';
import { ReportScadaEditConnectionForm } from './ReportScadaEditConnectionForm.jsx';
import { ReportScadaText } from './ReportScadaText.jsx';
import { ReportScadaNewText, ReportScadaNewTextForm } from './ReportScadaNewText.jsx';
import { ReportScadaEditTextForm } from './ReportScadaEditText.jsx';
import { ReportScadaStandardConnection } from './ReportScadaStandardConnection.jsx';
import { ReportScadaEditAccumulatorForm } from './ReportScadaEditAccumulator.jsx';
import { ReportScadaNewAccumulator, ReportScadaNewAccumulatorForm } from './ReportScadaNewAccumulator.jsx';
import { ReportScadaAccumulator } from './ReportScadaAccumulator.jsx';

const nodeTypes = {
  standardLocation: ReportScadaStandardLocation,
  text: ReportScadaText,
  accumulator: ReportScadaAccumulator,
};

const edgeTypes = {
  standardConnection: ReportScadaStandardConnection,
};

function ReportScadaGrid() {
  const ref = React.useRef(null);
  const project = new Project();
  const plotKeys = reportPlotKeysSelector() || [];
  const clickPosition = reportScadaClickPositionSelector();
  const updateClickPosition = reportScadaClickPositionDispatch();
  const updateNewConnectionPayload = reportScadaNewConnectionDispatch();
  const { fitView, screenToFlowPosition, flowToScreenPosition } = useReactFlow();
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [autoFitApplied, setAutoFitApplied] = React.useState(false);
  const setScadaLocked = reportScadaScadaLockedDispatch();
  const scadaLocked = reportScadaScadaLockedSelector();
  const setScadaUpdatePayload = reportScadaUpdateDispatch();
  const setScadaDeleteKey = reportScadaDeleteDispatch();
  const updateSelectedPlotKey = reportPlotSelectedKeyDispatch();
  const updateSelectedModalType = reportModalTypeDispatch();

  const onConnect = React.useCallback((edge) => {
    updateNewConnectionPayload(
      {
        source: edge.source,
        target: edge.target,
        source_handle: edge.sourceHandle,
        target_handle: edge.targetHandle,
      },
    );
  }, [updateNewConnectionPayload]);

  const onNodesChange = React.useCallback((changes) => {
    setNodes((eds) => applyNodeChanges(changes, eds));

    for (const change of changes) {
      switch (change.type) {
        case 'position':
          if (change.dragging) return;
          setScadaUpdatePayload({
            plotKey: change.id,
            plot_position: { x: change.position.x, y: change.position.y },
          });
          break;
        default:
          break;
      }
    }
  }, [setNodes, setScadaUpdatePayload]);

  const onPaneClick = React.useCallback(() => {
    if (!project.userCan('edit_reports')) return;

    updateClickPosition(null);
  }, [project, updateClickPosition]);

  const onRightClick = React.useCallback((event) => {
    if (scadaLocked) return;
    if (!project.userCan('edit_reports')) return;

    event.preventDefault();

    const position = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    updateClickPosition(position);
  }, [scadaLocked, project, updateClickPosition]);

  const onNodeDoubleClick = React.useCallback((event, node) => {
    updateSelectedPlotKey(node.data.plot.key);

    switch (node.type) {
      case 'standardLocation':
        updateSelectedModalType(MODAL_TYPES.SCADA_EDIT_LOCATION);
        break;
      case 'text':
        updateSelectedModalType(MODAL_TYPES.SCADA_EDIT_TEXT);
        break;
      case 'accumulator':
        updateSelectedModalType(MODAL_TYPES.SCADA_EDIT_ACCUMULATOR);
        break;
      default:
        break;
    }
  }, [updateSelectedPlotKey, updateSelectedModalType]);

  const onEdgeDoubleClick = React.useCallback((event, edge) => {
    updateSelectedPlotKey(edge.id);
    updateSelectedModalType(MODAL_TYPES.SCADA_EDIT_CONNECTION);
  }, [updateSelectedPlotKey, updateSelectedModalType]);

  const onNodesEdgesDelete = React.useCallback((event) => {
    setScadaDeleteKey(event[0].id);
  });

  React.useEffect(() => {
    setScadaLocked(true);

    if (!autoFitApplied) {
      setTimeout(fitView, 500);
      setAutoFitApplied(true);
    }
  }, [setScadaLocked, autoFitApplied, fitView]);

  // remove nodes for plots that have been deleted
  const filteredNodes = nodes.filter((node) => plotKeys.includes(node.data?.plot?.key));
  if (filteredNodes.length !== nodes.length) setNodes(filteredNodes);

  // remove edges for plots that have been deleted
  const filteredEdges = edges.filter((edge) => (plotKeys.includes(edge.id)));
  if (filteredEdges.length !== edges.length) setEdges(filteredEdges);

  return (
    <div className="border rounded-small" style={{ width: '100%', height: '100%' }}>
      { plotKeys.map((plotKey) => (
        <div key={plotKey}>
          <ReportPlotDataLoader plotKey={plotKey} />
          <ReportScadaPlotStateManager plotKey={plotKey} setNodes={setNodes} setEdges={setEdges} />
        </div>
      ))}
      <ReactFlow
        ref={ref}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onNodeDoubleClick={onNodeDoubleClick}
        onEdgeDoubleClick={onEdgeDoubleClick}
        onEdgesChange={onEdgesChange}
        onEdgesDelete={onNodesEdgesDelete}
        onNodesDelete={onNodesEdgesDelete}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        proOptions={{ hideAttribution: true }}
        defaultEdgeOptions={{ type: 'step', animated: true }}
        onPaneContextMenu={onRightClick}
        onPaneClick={onPaneClick}
        nodesDraggable={false}
        nodesConnectable={false}
        elementsSelectable={false}
      >
        <Background variant="dots" gap={12} size={1} />
        <Controls onInteractiveChange={(notLocked) => setScadaLocked(!notLocked)} />
        <ReportScadaPlotUpdater />
        <ReportScadaPlotRemover />
        <ReportScadaNewConnection />
        <ReportScadaNewLocationForm />
        <ReportScadaNewTextForm />
        <ReportScadaNewAccumulatorForm />
        <ReportScadaEditTextForm />
        <ReportScadaEditConnectionForm />
        <ReportScadaEditLocationForm />
        <ReportScadaEditAccumulatorForm />
        {clickPosition && (
          <ContextMenu
            clickX={flowToScreenPosition(clickPosition).x}
            clickY={flowToScreenPosition(clickPosition).y}
          >
            <ReportScadaNewLocation />
            <ReportScadaNewText />
            <ReportScadaNewAccumulator />
          </ContextMenu>
        )}
      </ReactFlow>
    </div>
  );
}

export function ReportScada() {
  return (
    <Container fluid className="pt-3 px-md-4" id="container-print" style={{ height: '100vh' }}>
      <ReportError />
      <Row className="gutter-2 flex-column" style={{ height: '100%' }}>
        <Col sm={12} style={{ flex: 0, height: 'auto' }}>
          <Row className="gutter-2">
            <ReportTopBar />
          </Row>
        </Col>
        <Col sm={12} style={{ flex: 1 }}>
          <ReactFlowProvider>
            <ReportScadaGrid />
          </ReactFlowProvider>
        </Col>
      </Row>
    </Container>
  );
}
