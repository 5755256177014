import { Handle, Position } from '@xyflow/react';
import React from 'react';
import { reportPlotDataSelector } from '../report-utils';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';

export const ReportScadaAccumulator = React.memo(({ data }) => {
  const plot = data.plot;
  const plotData = reportPlotDataSelector(plot.key);

  return (
    <div className="p-1">
      <Handle type="target" id="target-top" position={Position.Top} />
      <Handle type="source" id="source-top" position={Position.Top} />
      <Handle type="target" id="target-left" position={Position.Left} />
      <Handle type="source" id="source-left" position={Position.Left} />
      <Handle type="target" id="target-right" position={Position.Right} />
      <Handle type="source" id="source-right" position={Position.Right} />
      <Handle type="target" id="target-bottom" position={Position.Bottom} />
      <Handle type="source" id="source-bottom" position={Position.Bottom} />

      <div className="bg-light shadow-sm rounded text-center text-primary">
        <div className="border-bottom px-2 py-1">{plot.metricTitle}</div>
        <div className="font-weight-bold p-2">
          {plotData?.data?.total}
        </div>

        {plotData?.data?.reportPath && (
          <div className="p-1 border-top">
            <Button
              icon
              rounded
              size="xs"
              variant="secondary"
              href={plotData?.data?.reportPath}
              target="_blank"
            >
              <Icon name="area-chart" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
});
