import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Translator } from '../../../global/translator';
import {
  MODAL_TYPES,
  reportErrorDispatch,
  reportModalTypeDispatch,
  reportPlotKeysDispatch,
  reportPlotKeysSelector,
  reportPlotOptionsSelector, reportPlotSelectedKeyDispatch,
  reportPlotSelectedKeySelector,
  reportPlotSelector,
} from '../report-utils';
import { Input, Select } from '../../../duxfront/duxstrap/components/Form.jsx';
import { ReportPlotModalWrapper } from '../report_plot_base/ReportPlotModalWrapper.jsx';
import { ReportPlotFormWrapper } from '../report_plot_base/ReportPlotFormWrapper.jsx';
import { removePlot } from '../report_plot_base/ReportPlotDelete.jsx';
import { usePlotDataLoader } from '../report_plot_base/ReportPlotDataLoader.jsx';

export function ReportScadaEditConnectionForm() {
  const translator = new Translator();
  const plotOptions = reportPlotOptionsSelector();
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const updateError = reportErrorDispatch();
  const plotKeys = reportPlotKeysSelector();
  const updatePlotKeys = reportPlotKeysDispatch();
  const updateSelectedModalType = reportModalTypeDispatch();
  const updateSelectedPlotKey = reportPlotSelectedKeyDispatch();
  const { reloadPlotData } = usePlotDataLoader(reportPlotSelectedKey);
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const formId = 'scada_connection';
  const title = `${translator.get('general.edit')} ${translator.get('general.connection')}`;

  const removeConnection = React.useCallback(() => {
    removePlot(plot, plotKeys, updatePlotKeys, updateError);
    updateSelectedModalType(null);
    updateSelectedPlotKey(null);
  }, [plot, plotKeys, updatePlotKeys, updateError, updateSelectedModalType, updateSelectedPlotKey]);

  const afterSave = React.useCallback(() => {
    reloadPlotData();
    updateSelectedModalType(null);
    updateSelectedPlotKey(null);
  });

  return plotOptions && plot && (
    <ReportPlotModalWrapper title={title} modalType={MODAL_TYPES.SCADA_EDIT_CONNECTION}>
      <ReportPlotFormWrapper formId={formId} onRemove={removeConnection} afterSave={afterSave}>
        <Col sm={6}>
          <Select
            id="width"
            formId={formId}
            label={translator.get('general.width')}
            defaultValue={plot.config.plots[0].width}
            options={['1px', '2px', '3px', '4px', '5px']}
          />
        </Col>
        <Col sm={6}>
          <Input
            id="color"
            formId={formId}
            label={translator.get('general.color')}
            defaultValue={plot.config.plots[0].color}
            placeholder="#3c3c3c"
            validation="required"
            type="color"
          />
        </Col>

        <Col sm={12}>
          <Select
            id="animated_metric"
            formId={formId}
            label={translator.get('general.animated_metric')}
            defaultValue={plot.config.plots[0].animatedMetric}
            options={[
              { name: translator.get('general.none'), value: '' },
              ...plot.config.plots[0].metrics ?? [],
            ]}
          />
        </Col>
      </ReportPlotFormWrapper>
    </ReportPlotModalWrapper>
  );
}
