import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Translator } from '../../../global/translator';
import {
  MODAL_TYPES,
  reportErrorDispatch,
  reportModalTypeDispatch,
  reportPlotOptionsSelector,
  reportScadaClickPositionDispatch,
  reportScadaClickPositionSelector,
  reportSelector,
} from '../report-utils';
import { parseForm, patchJSON } from '../../../duxfront/plugins/dux-utils';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { ReportPlotNew } from '../report_plot_base/ReportPlotNew.jsx';
import { ContextMenuItem } from '../../../duxfront/duxdash/components/ContextMenu.jsx';
import { ReportFormWrapper } from '../report_base/ReportFormWrapper.jsx';
import { ReportModalWrapper } from '../report_base/ReportModalWrapper.jsx';
import { Input, Select } from '../../../duxfront/duxstrap/components/Form.jsx';

export function ReportScadaNewAccumulatorForm() {
  const self = React.createRef();
  const translator = new Translator();
  const plotOptions = reportPlotOptionsSelector();
  const updateClickPosition = reportScadaClickPositionDispatch();
  const report = reportSelector(['updatePath', 'templates', 'templateGroups']);
  const updateError = reportErrorDispatch();
  const clickPosition = reportScadaClickPositionSelector();
  const formId = 'scada_accumulator';
  const title = `${translator.get('general.add')} ${translator.get('general.accumulator')}`;
  const [newPlot, setNewPlot] = React.useState(null);
  const updateSelectedModalType = reportModalTypeDispatch();
  const scadaGroup = report?.templateGroups.find((g) => g.groupType === 'accumulator');
  const templateOptions = scadaGroup?.templates.map((t) => ({ name: t.title, value: t.key })) || [];

  const addScadaLocation = React.useCallback((e) => {
    e.preventDefault();

    const formData = parseForm(e.target).scada_accumulator;
    const payload = {
      authenticity_token: formData.authenticity_token,
      add_plot: {
        plot_type: 'scada_accumulator',
        scada_accumulator: {
          metric_title: formData.metric_title,
          template: formData.template,
          position: clickPosition,
        },
      },
    };

    patchJSON(
      report.updatePath,
      payload,
      (result) => {
        updateClickPosition(null);
        updateSelectedModalType(null);
        setNewPlot(result.added);
      },
      () => updateError('Failed to add new plot.'),
    );
  });

  return plotOptions && (
    <ReportModalWrapper modalType={MODAL_TYPES.SCADA_ADD_ACCUMULATOR} title={title}>
      <ReportFormWrapper self={self} formId={formId} onSave={addScadaLocation}>
        <Col sm={12}>
          <Input id="metric_title" formId={formId} label={translator.get('general.associated_metric_name')} />
        </Col>

        <Col sm={12}>
          <Select id="template" formId={formId} label={translator.get('general.template')} options={templateOptions} />
        </Col>
      </ReportFormWrapper>

      { newPlot && (
        <ReportPlotNew newPlot={newPlot} setNewPlot={setNewPlot} />
      )}
    </ReportModalWrapper>
  );
}

export function ReportScadaNewAccumulator() {
  const translator = new Translator();
  const updateSelectedModalType = reportModalTypeDispatch();

  return (
    <ContextMenuItem onClick={() => updateSelectedModalType(MODAL_TYPES.SCADA_ADD_ACCUMULATOR)}>
      <Icon name="plus" className="text-primary mr-2" />
      { `${translator.get('general.add')} ${translator.get('general.accumulator')}` }
    </ContextMenuItem>
  );
}
